import React from "react";
import { motion } from "framer-motion";
import { Box, Flex } from "rebass";

import { useStores } from "../store/useStores";

import { ZKBLogoLink } from "../components/ZKBLogoLink";
import { Title } from "../components/Title";

// Animation Variant
import { SinglePageVariants } from "../animations/Variants";

interface VideoProps {
	path: string;
	url: string;
	isExact: boolean;
	params: {
		id: string;
	};
}

export const Video = ({ match }: { match: VideoProps }) => {
	const { ProjectionStore } = useStores();

	const videoRef: React.MutableRefObject<HTMLVideoElement | null> = React.useRef(
		null
	);

	React.useEffect(() => {
		/**
		 * Check when video has ended and call a callback function
		 * @param el HTML5 Video Element
		 * @param cb callback function to call on video end
		 */
		const callbackOnVideoEnd = (el: HTMLVideoElement, cb: any) => {
			requestAnimationFrame(() => {
				if (el.ended) {
					cb();
				} else {
					callbackOnVideoEnd(el, cb);
				}
			});
		};

		const autoplayQueryParam = new URLSearchParams(window.location.search).get(
			"autoplay"
		);

		if (videoRef?.current) {
			videoRef.current.play();
		}

		if (videoRef?.current && autoplayQueryParam !== "false") {
			try {
				callbackOnVideoEnd(videoRef?.current, () =>
					ProjectionStore.next()
				);
			} catch (error) {
				console.log(error);
			}
		}
		//eslint-disable-next-line
	}, []);

	const VideoData = ProjectionStore?.events?.filter(
		data => data?.id === Number(match.params.id)
	)[0];

	return (
		<motion.div className='single' initial='exit' animate='enter' exit='exit'>
			<Flex flexDirection='column' alignItems='flex-start' p={["5vh"]}>
				<Title
					title={VideoData?.title}
					color={
						VideoData?.options?.color ? VideoData?.options.color : "#E1C26B"
					}
					sx={{
						fontSize: VideoData?.media ? "10vh" : "20vh",
						padding: VideoData?.media ? "0 1vh" : "0 2vh"
					}}
				/>
				<Box
					sx={{
						height: ["65vh"],
						"& video": { height: "100%", maxWidth: "100%", display: "block" }
					}}
				>
					<motion.video
						ref={videoRef}
						variants={SinglePageVariants.imageVariants}
						src={VideoData?.media}
						title={VideoData?.title}
						muted
					/>
				</Box>

				<ZKBLogoLink />
			</Flex>
		</motion.div>
	);
};
