export const transition = { duration: 0.5, ease: [ 0.43, 0.13, 0.23, 0.96 ] };

export const EventListVariants = {
    imageVariants: {
        hover: { scale: 1 }
    },
    frameVariants: {
        hover: { scale: 0.95 }
    },
    headlineVariants: {
        exit: { y: "50%", x: "0%", opacity: 0, transition },
        enter: {
            y: "0%",
            x: "0%",
            opacity: 1,
            transition
        }
    },
    thumbnailVariants: {
        initial: { scale: 0.9, opacity: 0 },
        enter: { scale: 1, opacity: 1, transition },
        exit: {
            scale: 0.5,
            opacity: 0,
            transition: { ...transition }
        }
    }
}

export const SinglePageVariants = {
    backVariants: {
        exit: { x: 100, opacity: 0, transition },
        enter: { x: 0, opacity: 1, transition: { delay: 1, ...transition } }
    },
    imageVariants: {
        exit: { y: "10%", opacity: 0, transition },
        enter: {
            y: "0%",
            opacity: 1,
            transition
        }
    },
    headlineVariants: {
        exit: { y: "50%", x: "0%", opacity: 0, transition },
        enter: {
            y: "0%",
            x: "0%",
            opacity: 1,
            transition
        }
    }
}