import { observer } from "mobx-react-lite";
import { useStores } from "../store/useStores";
import { Button } from "rebass";

export const DeviceOrientationToggle = observer(() => {
	const { ProjectionStore } = useStores();

	return <Button 
		sx={{ 
			position: 'fixed', 
			right: 2, 
			top: 2, 
			bg: 'black', 
			zIndex: 100, 
			cursor: 'pointer', 
			opacity: 0,
			textTransform: 'capitalize',
			'&:hover': {
				opacity: 1
			}
		}} 
		onClick={() => {
			ProjectionStore.toggleDeviceOrientation();
		}}
	>
		Mode: {ProjectionStore.deviceOrientation}
	</Button>
})
