import React from "react";
import { motion } from "framer-motion";
import { Box, Text, TextProps } from "rebass";
import { observer } from "mobx-react-lite";

import { SinglePageVariants } from "../animations/Variants";
import { useStores } from "../store/useStores";
interface Props extends TextProps {
	text: string;
	animate?: boolean;
}

export const SplitBodyText = observer(({ children, sx, animate, text, ...rest }: Props) => {
	const { ProjectionStore } = useStores();

	const isPortraitMode = ProjectionStore.deviceOrientation === "portrait";

	const textArray = text?.split(" ");

	return (
		<Box sx={{
			position: "relative",
			maxWidth: "65vw",
		}}>
			<motion.div variants={animate === true ? SinglePageVariants.headlineVariants : undefined}>
				{textArray
					? textArray.map(i => (
						<Box
							sx={{
								display: "inline-block",
								position: "relative",
								marginTop: ["-0.24vh"],
								left: isPortraitMode ? "2.5vh" : "4vh",
								padding: isPortraitMode ? ".5vh 1vh" : "1vh",
								marginRight: "-1vh",
								fontSize: isPortraitMode ? "3.25vh" : "4.5vh",
								background: "#1A1919",
								lineHeight: 0.9,
								color: "#E1C26B", // Default color
								fontWeight: "600",
								textTransform: "uppercase",
								marginBottom: "-1vh",
								...sx
							}}
						>
							<Text {...rest}>{i}</Text>
						</Box>
					))
					: null}
			</motion.div>
		</Box>
	);
});
