import React from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../store/useStores";
import { DeviceOrientationToggle } from "./DeviceOrientationToggle";

// Types
import { useHistory } from "react-router-dom";

export const Layout: React.FC<any> = observer(props => {
	const history = useHistory();
	const { ProjectionStore } = useStores();

	// Start playing projections on initial mount
	React.useEffect(() => {
		ProjectionStore.setRouterHistory(history);
		// ProjectionStore.playProjections();
		//eslint-disable-next-line
	}, []);

	return <>
		<DeviceOrientationToggle/>
		{props.children}
	</>;
});
