import React from "react";
import { motion } from "framer-motion";
import { Box, Text, TextProps } from "rebass";
import { observer } from "mobx-react-lite";

import { SinglePageVariants } from "../animations/Variants";
import { hasUmlaut } from "../helper/UmlautRegexp";
import { useStores } from "../store/useStores";

interface Props extends TextProps {
	title: string | undefined;
}

export const Title = observer(({ title, sx, ...rest }: Props) => {
	const { ProjectionStore } = useStores();

	const isPortraitMode = ProjectionStore.deviceOrientation === "portrait";

	if (!title) return null;
	const isUmlautInTitle = hasUmlaut(title);
	const titleArray = title?.split(" ");

	return (
		<Box sx={{
			position: "relative",
			maxWidth: "70vw",
		}}>
			<motion.div
				variants={SinglePageVariants.headlineVariants}
			>
			{titleArray
				? titleArray.map(i => (
							<Box
								key={i}
								sx={{
									display: "inline-block",
									position: "relative",
									marginTop: ["-0.8vh"],
									marginRight: -1,
									left: isPortraitMode ? "2.5vh" : "4vh",
									padding: "1vh",
									fontSize: ["10vh"],
									background: "#1A1919",
									lineHeight: isUmlautInTitle ? 1.25 : 0.9,
									color: "#E1C26B", // Default color
									fontWeight: "600",
									textTransform: "uppercase",
									...sx
								}}
							>
								<Text {...rest}>{i}</Text>
							</Box>
				  ))
					: null}
				</motion.div>
		</Box>
	);
});
