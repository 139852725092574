import { observable, action } from "mobx";
import { chunk, debounce, clamp } from "lodash";
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";

// Types
import { RouterChildContext } from "react-router-dom";

// Register ScrollToPlugin
gsap.registerPlugin(ScrollToPlugin);

export interface Projection {
	id: number;
	type: string | "flyer" | "video";
	state: string | "active" | "inactive" | "preview";
	media?: string;
	mediaCredits?: string;
	title?: string;
	text?: string;
	date?: string;
	options?: ProjectionOptions; // Future options for each projection
}

export interface ProjectionOptions {
	color?: string;
}

export interface Advertisement {
	id: number;
	type: 'image' | string;
	media: string;
}

export type PlaylistItemType = 'PROJECTION' | 'ADVERTISEMENT' | 'EVENT_LIST' | 'EVENT_PROGRAM_LIST';
export interface PlaylistItem<Type extends PlaylistItemType, Data> {
	type: Type;
	duration: number;
	onStart: () => void;
	onEnd?: () => void;
	nextCallback?: () => void;
	data: Data;
};

export type Playlist = Array<
	PlaylistItem<'PROJECTION', Projection> |
	PlaylistItem<'ADVERTISEMENT', Advertisement> |
	PlaylistItem<'EVENT_LIST', Projection[]> |
	PlaylistItem<'EVENT_PROGRAM_LIST', Projection[]>
>;

type ApiRoute = '/events' | '/advertisements';

// Constants
const API_URL = 'https://api.kaufleuten.ch';

export type InternalRoute = `/`
	| `/eventlist/${number}`
	| `/flyer/${number}`
	| `/video/${number}`
	| `/advertisement/${number}`
	| `/program`;

/**
 * Generate a playlist based on the events and advertisements provided.
 *
 * @param param0 Store, events and advertisements data
 * @returns A newly generated playlist
 */
const createPlaylist = ({
	store,
	events,
	advertisements
}: {
	store: ProjectionStore;
	events: Projection[];
	advertisements: Advertisement[];
}): Playlist => {

	/**
	 * Query param to control the playback speed of all individual projections
	 */
	const speedQueryParam = Number(new URLSearchParams(window.location.search).get("speed") || 1);

	/** Array that accumulates the finished playlist */
	const playlistArr: Playlist = [];

	/** Event list chunk size.
	 * This is split through the amount of advertisements,
	 * to display an event amount of events and advertisements.
	 */
	const EVENT_CHUNK_SIZE = Math.max(advertisements.length / 2, 1);

	/** Chunk events into groups to create small lists of events */
	const eventChunks = chunk(events, EVENT_CHUNK_SIZE);

	/** Iterate over event chunks and add advertisements in between */
	eventChunks.forEach((eventChunk, index) => {
		// Add events as list
		/*const EVENT_LIST_WAIT = 2000;
		const EVENT_LIST_SCROLL_DURATION = (eventChunk.length * 15000) / speedQueryParam;
		const EVENT_LIST_DURATION =
			// Wait at the start for 2 secons
			EVENT_LIST_WAIT +
			// Scroll down all the events until the end
			// 5 seconds for each event
			EVENT_LIST_SCROLL_DURATION +
			// Wait at the end for 2 seconds
			EVENT_LIST_WAIT;
		playlistArr.push({
			type: 'EVENT_LIST',
			duration: EVENT_LIST_DURATION,
			onStart: () => {
				window.scrollTo(0, 0);

				setTimeout(() => {
					const y = document.body.clientHeight - window.innerHeight;

					gsap.to(
						window,
						{
							duration: EVENT_LIST_SCROLL_DURATION / 1000,
							scrollTo: { y },
							ease: "none"
						}
					);
				}, EVENT_LIST_WAIT)

				setTimeout(() => {
					store.next();
				}, EVENT_LIST_DURATION)
			},
			data: eventChunk
		})*/

		// Add events as single projection
		const EVENT_PROJECTION_WAIT = 2000;
		const EVENT_PROJECTION_DURATION = clamp( 5000 / speedQueryParam, 1000, 10000);
		eventChunk.forEach(event => {
			playlistArr.push({
				type: 'PROJECTION',
				duration: EVENT_PROJECTION_DURATION,
				data: event,
				onStart: () => {
					setTimeout(() => {
						store.next();
					}, EVENT_PROJECTION_WAIT + EVENT_PROJECTION_DURATION)
				}
			})
		})

		/** Constant to control the advertisements duration */
		const ADVERTISEMENT_DURATION = clamp(15 * 1000 / speedQueryParam, 5000, 30000);
		/** Copy of advertisements that can be mutated */
		/** Add advertisements in between event lists. Don't add ads at the end. */
		if (index !== eventChunks.length - 1) {
			const nextAdvertisement = advertisements[index];

			if (nextAdvertisement) {
				playlistArr.push({
					type: 'ADVERTISEMENT',
					duration: advertisements.length * ADVERTISEMENT_DURATION,
					data: nextAdvertisement,
					onStart: () => {
						setTimeout(() => {
							store.next();
						}, ADVERTISEMENT_DURATION)
					}
				})
			}
		}
	})

	/** Add single event projections to the playlist. */
	/*const EVENT_PROJECTION_DURATION = 5000;
	events.forEach(event => {
		playlistArr.push({
			type: 'PROJECTION',
			duration: EVENT_PROJECTION_DURATION,
			data: event,
			onStart: () => {
				setTimeout(() => {
					store.next();
				}, EVENT_PROJECTION_DURATION)
			}
		})
	})/

	/** Add event program list to the playlist */
	/*const EVENT_PROGRAM_LIST_DURATION = 2000 + (events.length * 2000 / speedQueryParam) + 2000
	playlistArr.push({
		type: 'EVENT_PROGRAM_LIST',
		duration: EVENT_PROGRAM_LIST_DURATION,
		onStart: () => {
			window.scrollTo(0, 0);

			setTimeout(() => {
				const y = document.body.clientHeight - window.innerHeight;

				gsap.to(
					window,
					{
						duration: events.length * 2 / speedQueryParam,
						scrollTo: { y },
						ease: "none"
					}
				);
			}, 2000)

			setTimeout(() => {
				store.next();
			}, EVENT_PROGRAM_LIST_DURATION)
		},
		data: events
	});*/

	// For local debugging only
	if (window.location.hostname === 'localhost') {
		console.log("💿 playlist", playlistArr)

		const totalTime = playlistArr.reduce((prev, cur) => {
			return prev + cur.duration
		}, 0)
		console.log("events:", events.length);
		console.log("ads:", advertisements.length);
		console.log("totalTime: seconds", totalTime / 1000);
		console.log("totalTime: minutes", totalTime / 1000 / 60);
	}

	return playlistArr
}

export class ProjectionStore {
	// Device orientation query param setting
	@observable
	deviceOrientation: 'landscape' | 'portrait' = 'landscape';

	// Autoplay query param setting
	autoplay: boolean = true;

	// Playlist data
	@observable
	playlist: Playlist = [];

	// Playlist current index
	@observable
	playlistIndex: number = 0;

	// Event state
	@observable
	events: Projection[] = [];

	// Advertisement data
	@observable
	advertisements: Advertisement[] = [];

	// React Router History
	@observable
	history: RouterChildContext["router"]["history"] | null = null;

	constructor() {
		// Get query params from URL
		const queryParamsString = window.location.search.split('?')[1];
		const queryParamsObj = new URLSearchParams(queryParamsString);

		// Get the orientation query param
		const orientation = queryParamsObj.get('orientation');
		if (orientation && (orientation === 'portrait' || orientation === 'landscape')) {
			this.deviceOrientation = orientation;
		}

		// Get the autoplay query param
		const autoplay = queryParamsObj.get('autoplay');
		if (autoplay && (autoplay === 'true' || autoplay === 'false')) {
			this.autoplay = autoplay === 'true';
		}

		Promise.allSettled(
			[fetchEvents(this), fetchAdvertisements(this)]
		).then(([events, advertisements]) => {
			if (
				events.status === 'fulfilled' &&
				events.value &&
				advertisements.status === 'fulfilled' &&
				advertisements.value &&
				this.playlist.length === 0
			) {
				const playlist = createPlaylist({ store: this, events: events.value, advertisements: advertisements.value }) as Playlist;
				this.playlist = playlist;
				if (this.autoplay) {
					this.next();
				}
			}
		})
			.catch(err => {
				if (err instanceof Error) {
					console.error(err);
				}
			})

		// For local debugging only
		if (window.location.hostname === 'localhost') {
			// @ts-ignore
			window.ProjectionStore = this;
		}
	}

	/**
	 * Function to trigger a reload of the page with the correct query params settings
	 */
	reload = () => {
		const protocol = window.location.protocol;
		const host = window.location.host;
		const search = `?orientation=${this.deviceOrientation}&autoplay=${this.autoplay}`;
		window.location.replace(`${protocol}//${host}${search}`);
	}


	next = debounce(action(() => {
		const next = this.playlist[this.playlistIndex];
		let nextRoute: InternalRoute;
		switch (next.type) {
			case 'EVENT_LIST':
				nextRoute = `/eventlist/${this.playlistIndex}`;
				this.history?.push(nextRoute);
				next.onStart();
				break;
			case 'ADVERTISEMENT':
				nextRoute = `/advertisement/${next.data.id}`;
				this.history?.push(nextRoute);
				next.onStart();
				break;
			case 'PROJECTION':
				nextRoute = `/flyer/${next.data.id}`;
				this.history?.push(nextRoute);
				next.onStart();
				break;
			case 'EVENT_PROGRAM_LIST':
				nextRoute = `/program`;
				this.history?.push(nextRoute);
				next.onStart();
				break;
			default:
				break;
		}
		// Increment the index or start at 0 again.
		const nextIndex = this.playlistIndex + 1;
		if (nextIndex === this.playlist.length) {
			this.playlistIndex = 0;

			// Reload the page with the correct query params when in portrait mode
			if (this.deviceOrientation === 'portrait') {
				this.reload();
			}
		} else {
			this.playlistIndex = nextIndex;
		}
	}), 2000, { leading: true, trailing: false })

	setRouterHistory(history: RouterChildContext["router"]["history"]) {
		this.history = history;
	}

	/**
	 * Toggle the device orientation
	 */
	@action
	toggleDeviceOrientation = () => {
		this.deviceOrientation = this.deviceOrientation === 'portrait' ? 'landscape' : 'portrait';
	}

	/**
	 * Prints out all data to the console.
	 */
	@action
	printInternalState() {
		console.log("🛑 this.events", JSON.stringify(this.events, null, 2));
		console.log("🛑 this.advertisements", JSON.stringify(this.advertisements, null, 2));
	}
}

/**
 * Fetch events and add them to the store
 *
 * @param {ProjectionStore} store the store
 */
const fetchEvents = (store: ProjectionStore) => fetchAPI('/events', new URLSearchParams({ perPage: "100" })).then(json => {
	store.events = json;
	return json;
});

/**
 * Fetch advertisements and add them to the store
 *
 * @param {ProjectionStore} store the store
 */
const fetchAdvertisements = (store: ProjectionStore) => fetchAPI('/advertisements', new URLSearchParams({ perPage: "100" })).then(json => {
	store.advertisements = json;
	return json;
});

/**
 *
 * @param route
 * @returns
 */
const fetchAPI = async (route: ApiRoute, urlParams: URLSearchParams | null = null) => {
	try {
		const response = await fetch(`${API_URL}${route}?${urlParams?.toString()}`,);
		const json = await response.json()
		return json;
	} catch (error) {
		if (error) {
			console.log(error);
		}
	}
}
