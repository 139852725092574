import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Flex, Box } from "rebass";
import { observer } from "mobx-react-lite";

import { useStores } from "../store/useStores";
import { Projection } from "../store/ProjectionStore";

import { Title } from "../components/Title";
import { BodyText } from "../components/BodyText";
import ZKBLogo from "../components/ZKBLogo";
import { ZKBLogoLink } from "../components/ZKBLogoLink";

// Animation Variant
import { EventListVariants, SinglePageVariants, transition } from "../animations/Variants";

const ListItem = observer(({ data }: { data: Projection | undefined }) => {
	if (typeof data === "undefined" || !data.date) return null;
	const hasMedia = data.media && data.media.length > 0;

	return (
		<Flex
			alignItems='center'
			py={5}
			px={4}
			sx={{ width: "100%", maxWidth: "100vw", cursor: "pointer" }}
		>
			<motion.div variants={EventListVariants.thumbnailVariants} key={`EventList.${data.id}`} >
				<motion.div
					whileHover='hover'
					variants={EventListVariants.frameVariants}
					transition={transition}
				>
					<Link to={`/${data.type}/${data.id}?autoplay=false`}>
						<Flex flexDirection='column' alignItems='flex-start'>
							<Title
								title={data.title}
								color={
									data?.options?.color
										? data?.options.color
										: "#E1C26B"
								}
							/>
							{hasMedia && (
								<Box
									className="loading-container"
									sx={{
										height: ["auto"],
										minHeight: "200px",
										position: "relative",
										mb: ["0.24vh"],
										zIndex: 1,
										background: `linear-gradient(to right, #1A1919 8%, ${data?.options?.color || "#eee"} 38%, #1A1919 54%)`,
										"& img": {
											height: "auto",
											width: "auto",
											maxHeight: "60vh",
											maxWidth: "100%"
										}
									}}>
									<motion.img
										src={data.media}
										alt={data.text}
										title={data.title}
										variants={EventListVariants.imageVariants}
										transition={transition}
										style={{ display: "block" }}
									/>
								</Box>
							)}
							<BodyText
								fontWeight={400}
								color={
									data?.options?.color
										? data?.options.color
										: "#E1C26B"
								}
							>
								{data?.date}
							</BodyText>
							<BodyText
								color={
									data?.options?.color
										? data?.options.color
										: "#E1C26B"
								}
							>
								{"Kaufleuten"}
							</BodyText>
							<BodyText
								maxWidth={["40vw", "50vw", "60vw"]}
								fontWeight={400}
								color={
									data?.options?.color
										? data?.options.color
										: "#E1C26B"
								}
							>
								{data?.text}
							</BodyText>
						</Flex>
					</Link>
				</motion.div>
			</motion.div>
		</Flex>
	);
});

interface RouterMatchProps {
	path: string;
	url: string;
	isExact: boolean;
	params: {
		index: string;
	};
}

export const EventList = observer(({ match }: { match: RouterMatchProps }) => {
	const { ProjectionStore } = useStores();

	const playlist = ProjectionStore?.playlist;
	const playlistIndex = Number(match.params.index || 0);

	if (!playlist[playlistIndex]) return null;

	return (
		<Box
			sx={{
				padding: "5vh",
				margin: "0 auto",
				width: "100%",
				position: "relative"
			}}
		>
			<Box
				sx={{
					position: 'relative',
					left: 5,
					mb: 4,
					"& svg": {
						height: "20vh",
						width: "auto"
					}
				}}
			>
				<motion.div variants={SinglePageVariants.backVariants}>
					<Link to='/?autoplay=false'>
						<ZKBLogo />
					</Link>
				</motion.div>
			</Box>

			<motion.div
				initial='initial'
				animate='enter'
				exit='exit'
				key="EventList"
			>
				<Flex flexWrap='wrap' justifyContent='space-between'>
					{(playlist[playlistIndex]?.data as Projection[])?.map?.(data => (
						<ListItem key={data?.id} data={data} />
					))}
				</Flex>
			</motion.div>

			<ZKBLogoLink />
		</Box>
	);
});
