import React from "react";
import { motion } from "framer-motion";
import { Box, Text, TextProps } from "rebass";

import { SinglePageVariants } from "../animations/Variants";

interface Props extends TextProps {
	animate?: boolean;
}

export const BodyText = ({ children, sx, animate, ...rest }: Props) => {
	return (
		<motion.div variants={animate === true ? SinglePageVariants.headlineVariants : undefined}>
			<Box
				sx={{
					display: "inline-block",
					position: "relative",
					marginTop: ["-0.24vh"],
					left: "4vh",
					padding: "1vh",
					fontSize: ["4.5vh"],
					background: "#1A1919",
					lineHeight: 0.9,
					color: "#E1C26B", // Default color
					fontWeight: "600",
					textTransform: "uppercase",
					marginBottom: "-1vh",
					...sx
				}}
			>
				<Text {...rest}>{children}</Text>
			</Box>
		</motion.div>
	);
};
