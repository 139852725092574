import React from "react";
import { motion } from "framer-motion";
import { Flex, Box } from "rebass";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";

import { useStores } from "../store/useStores";
import { Projection } from "../store/ProjectionStore";

import { Title } from "../components/Title";
import { BodyText } from "../components/BodyText";

// Animation Variant
import { EventListVariants, transition } from "../animations/Variants";

const StyledFlex = styled(Flex)`
	position: relative;
	flex-wrap: wrap;
	align-items: baseline;
	background: #f9fbf8;

	&::after {
		z-index: -1;
		content: '';
		display: block;
		position: absolute;
		top:  -0.5vh;
		right:  -2vh;
		bottom: 0;
		left: 0;
		background: black;
	}
`

const ListItem = observer(({ data }: { data: Projection | undefined }) => {
	if (typeof data === 'undefined') return null;


	const dateArray = data.date?.split(' ') || [];
	let dateDay;
	let dateMonth;
	let dateYear;

	// 1. August 2020
	if ( dateArray.length === 3 ) {
		dateDay = dateArray?.[0];
		dateMonth = dateArray?.[1].substr(0, 3);
		dateYear = dateArray?.[2] || '';
	// August 2020
	} else if ( dateArray.length === 2 ) {
		dateMonth = dateArray?.[0];
		dateYear = dateArray?.[1] || '';
	// August
	} else if ( dateArray.length === 1 ) {
		dateMonth = dateArray?.[0];
	}

	const today = new Date();
	if ( dateYear === today.getFullYear().toString() ) {
		dateYear = '';
	}

	return (
		<Flex
			justifyContent='flex-start'
			alignItems='center'
			sx={{ width: "100%", mb: "0.5vh" }}
		>
			<motion.div variants={EventListVariants.thumbnailVariants} key={`EventProgramList.${data.id}`} >
				<motion.div
					whileHover='hover'
					variants={EventListVariants.frameVariants}
					transition={transition}
				>
					<StyledFlex>
						<BodyText
							fontWeight={400}
							color={
								data?.options?.color
									? data?.options.color
									: "#E1C26B"
							}
							sx={{ bg: '#f9fbf8', background: '#f9fbf8', marginTop: 0, marginRight: 0, left: 0, marginBottom: 0, fontSize: ["3.5vh"] }}
						>
							{dateDay} {dateMonth} { dateYear }
						</BodyText>
						<Title
							title={data.title}
							color={
								data?.options?.color
									? data?.options.color
									: "#E1C26B"
							}
							sx={{ bg: '#f9fbf8', background: '#f9fbf8', marginTop: 0, marginRight: 0, left: 0, marginBottom: 0, display: "inline-flex" }}
						/>

						<BodyText
							fontWeight={400}
							color={
								data?.options?.color
									? data?.options.color
									: "#E1C26B"
							}
							sx={{ bg: '#f9fbf8', background: '#f9fbf8', marginTop: 0, marginRight: 0, left: 0, marginBottom: 0, fontSize: ["3.5vh"] }}
						>
							{data?.text}
						</BodyText>
					</StyledFlex>
				</motion.div>
			</motion.div>
		</Flex>
	);
});

export const EventProgramList = observer(() => {
	const { ProjectionStore } = useStores();

	const playlist = ProjectionStore?.playlist;
	const playlistIndex = ProjectionStore?.playlistIndex;

	const EventProgramData = playlist[playlistIndex]?.data as Projection[];

	if (!EventProgramData && playlist[playlistIndex]?.type !== 'EVENT_PROGRAM_LIST') return null;

	playlist[playlistIndex]?.onStart?.();

	return (
		<Box
			sx={{
				padding: "5vh",
				margin: "0 auto",
				width: "100%",
				position: "relative"
			}}
		>
			<motion.div
				initial='initial'
				animate='enter'
				exit='exit'
				variants={{ exit: { transition: { staggerChildren: 0.1 } } }}
			>
				<Flex flexDirection='column' alignItems='flex-start'>
					<Title
						title={"KAUFLEUTEN"}
						color={"#FFFFFF"}
						sx={{ bg: 'black', marginTop: 0, marginRight: 0, left: 0, marginBottom: 0, fontSize: "15vh", marginLeft: "5vh" }}
					/>
					{EventProgramData?.map?.(data => (
						<ListItem key={data?.id} data={data} />
					))}
					<Title
						title={"KAUFLEUTEN"}
						color={"#FFFFFF"}
						sx={{ bg: 'black', marginTop: 0, marginRight: 0, left: 0, marginBottom: 0, fontSize: "12.5vh", marginLeft: "5vh" }}
					/>
					<Title
						title={"KULTUR"}
						color={"#FFFFFF"}
						sx={{ bg: 'black', marginTop: 0, marginRight: 0, left: 0, marginBottom: 0, fontSize: "12.5vh", marginLeft: "5vh", fontWeight: "400" }}
					/>
				</Flex>
			</motion.div>
		</Box>
	);
});
