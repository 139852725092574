import React from "react";
import { ProjectionStore } from "./ProjectionStore";

export const StoresContext = React.createContext({
	ProjectionStore: new ProjectionStore()
});

export const StoreProvider = ({ children }: any) => (
	<StoresContext.Provider
		value={{
			ProjectionStore: new ProjectionStore()
		}}
	>
		{children}
	</StoresContext.Provider>
);
