import React from "react";
import { Box } from "rebass";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import ZKBLogo from "./ZKBLogo";
import { useStores } from "../store/useStores";
import { SinglePageVariants } from "../animations/Variants";

export const ZKBLogoLink = observer((props: any) => {
	const { ProjectionStore } = useStores();

	const isPortraitMode = ProjectionStore.deviceOrientation === "portrait";

	return (<Box
		sx={{
			zIndex: 1,
			bg: "#f9fbf8",
			p: "1vh",
			bottom: isPortraitMode ? "2vh" : "4vh",
			right: isPortraitMode ? "2vh" : "4vh",
			position: "fixed",
			"& svg": {
				height: isPortraitMode ? "3vh" : "8vh",
				width: "auto"
			}
		}}
	>
		<motion.div variants={SinglePageVariants.backVariants}>
			<Link to='/?autoplay=false'>
				<ZKBLogo />
			</Link>
		</motion.div>
	</Box>
	)
});
