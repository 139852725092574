import { motion } from "framer-motion";
import { Flex } from "rebass";
import { observer } from "mobx-react-lite";

// Internal dependencies
import { useStores } from "../store/useStores";

// Animation Variant
import { SinglePageVariants } from "../animations/Variants";

interface AdvertisementProps {
	path: string;
	url: string;
	isExact: boolean;
	params: {
		id: string;
	};
}

export const Advertisement = observer(({ match }: { match: AdvertisementProps }) => {
	const { ProjectionStore } = useStores();

	const AdvertisementData = ProjectionStore?.advertisements.filter(
		data => String(data?.id) === match.params.id
	)[0];

	if (!AdvertisementData?.id) return null;

	return (
		<motion.div initial='exit' animate='enter' exit='exit' key={`Advertisement.${AdvertisementData.id}`}>
			<Flex
				flexDirection='column'
				alignItems='center'
				justifyContent='center'
				sx={{
					height: ["100vh"],
					objectFit: "contain", "& img": { maxHeight: "100%", maxWidth: "100%" }
				}}>
				<motion.img
					variants={SinglePageVariants.imageVariants}
					src={AdvertisementData?.media}
				/>
			</Flex>
		</motion.div>
	);
});
