import React from "react";
import { AnimatePresence } from "framer-motion";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Internal dependencies
import { Flyer } from "./pages/Flyer";
import { Video } from "./pages/Video";
import { Advertisement } from "./pages/Advertisement";
import { EventList } from "./pages/EventList";
import { EventProgramList } from "./pages/EventProgramList";

// Internal Styles
import "./App.css";
import "./fonts/fonts.css";

// Internal state store
import { StoreProvider } from "./store/StoresContext";
import { Layout } from "./components/Layout";

const App: React.FC = () => {
	return (
		<Router>
			<StoreProvider>
				<Layout>
					<Route
						render={({ location }) => (
							<AnimatePresence exitBeforeEnter initial={false}>
								<Switch location={location} key={location.pathname}>
									<Route exact path='/' component={EventList} />
									<Route exact path='/eventlist/:index' component={EventList} />
									<Route exact path='/flyer/:id' component={Flyer} />
									<Route exact path='/video/:id' component={Video} />
									<Route
										exact
										path='/advertisement/:id'
										component={Advertisement}
									/>
									<Route
										exact
										path='/program'
										component={EventProgramList}
									/>
								</Switch>
							</AnimatePresence>
						)}
					/>
				</Layout>
			</StoreProvider>
		</Router>
	);
};

export default App;
